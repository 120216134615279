@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');



.faq-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgba(249, 249, 250, 1);
    padding-bottom: 10rem;
}

.faq-item {
    margin-bottom: 30px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    cursor: pointer;
}

.faq-question{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color:  #F4F4F4;
    border: none;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 1.25rem;
    width: 60%;
    margin: 0px auto;
    transition: 0.4s;
}

.faq-question button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}


.faq-answer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #A3D8A3;
    color: black;
    border: none;
    width: 60%;
    font-size: 1rem;
    line-height: 25px;
    cursor: pointer;
    margin: 0 auto;
    padding: 15px 10px;
    transition: padding 0.2s ease-out;
    
} 


.faq-container > h1{
    font-family: "Montserrat";
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 6px;
    text-align: center;
    margin: 2rem 0;
    /* padding-top: 1rem; */

}

@media screen and (max-width: 900px) {
    .faq-question{
        font-size: 13px;
        line-height: 23px;
        width: 70%;
    }

    .faq-answer{
        font-size: 13px;
        line-height: 23px;
        width: 70%;
    }

    .faq-question > button > img{
        width: 11px;
    }
}