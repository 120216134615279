@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.refer-main{
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: rgba(249, 249, 250, 1);
}

.referal-top{
    margin: 10vh 0;
    align-items: center;
    text-align: center;
}

.referal-top > h1{
    font-size: 2.813vw;
    font-weight: 700;
    letter-spacing: -0.06em;
    text-align: center;
    margin: 0;
    padding: 0;
}

.referal-top > h2{
    font-size: 2.813vw;
    font-weight: 700;
    letter-spacing: -0.06em;
    width: 40%;
    text-align: center;    
    color: rgba(31, 170, 8, 1);
    margin: 0 auto;
    padding: 0;
}

.referal-main-ctn{
    display: flex;
    flex-direction: row;

    justify-content: center;
    width: 80vw;
    margin: 3rem auto 7rem auto;
}

.referal-step-main1{
    margin-bottom: 4rem;
}

.referal-step-main > a{
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
    font-weight: 500;
    font-family: "Inter";
}


.referal-step-main1 > a{
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
    font-weight: 500;
    font-family: "Inter";
    margin-top: 4rem;
}

.referal-code-ctn > p{
    font-family: "Inter";
    font-size: 0.938vw;
    font-weight: 400;
    text-align: center;
    margin: 20px 0;
    color: rgba(0, 0, 0, 1);    
}


.referal-ctn{
    align-items: center;
    text-align: center;
    padding: 4% 0 0 0;
    width: 36vw;
    height: fit-content;
    background-color: rgba(242, 244, 247, 1);
    margin-right: 5vw;
}

.referal-ctn > p{
    font-size: 0.938vw;
    padding: 0.7rem 0;
    margin: 0;
    background-color: rgba(255, 188, 15, 1);
}

.referal-ctn > p > span{
    font-weight: bold;
}

.referal-ctn > h1{
    font-size: 1.146vw;
    font-weight: 700;
}

.referal-ctn > h2{
    font-size: 1.042vw;
    font-weight: normal;
}

.referal-step-main{
    padding: 1rem 4rem 4rem 4rem;
}

.referal-step-main1{
    padding: 1rem 4rem 4rem 4rem;
}

.referal-step{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.referal-step > p{
    font-size: 0.938vw;
    margin-left: 1rem;
    text-align: left;
}

.referal-step > p > span{
    font-weight: bold;
}

.referal-code {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px 1.5rem;
    background-repeat: 5px;
    width: 75%;
    margin: 1rem auto;
}

.referal-code > p{
    font-size: 2.083vw;
    font-weight: 600;
    color: rgba(31, 170, 8, 1);
    margin: 0;
    padding: 0;
    font-family: "Inter";
}

.refer-share{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: fit-content;
    padding: 0 3%;
    background-color: rgba(31, 170, 8, 1);
    border-radius: 5px;
}

.refer-share > p{
    font-family: 'Inter';
    font-size: 0.833vw;
    font-weight: 400;
    text-align: left;
    color: rgba(255, 255, 255, 1);  
    margin: 0 5px;  
}

.refer-share > img{
    cursor: pointer;
}


.referal-ctn1{
    align-items: center;
    text-align: center;
    padding: 0;
    width: 35vw;
    padding: 1rem 0;
    background-color: rgba(242, 244, 247, 1);
}

.referal-ctn1 > h2{
    font-size: 0.938vw;
    margin: 3rem 0 0 0;
    padding: 0;
}

.referal-ctn1 > p{
    font-size: 0.938vw;
    margin: 0 0 7vh 0;
    padding: 0 7vw;
}

.referal-ctn1 > p > span{
    font-weight: bold;
}

.referal-ctn1 > h1{
    color: rgba(31, 170, 8, 1);
    font-size: 1.25vw;
    font-weight: 700;
    margin: 4vh 0 5vh 0;
}


.referal-details{
    align-items: center;
    text-align: center;
    width: 31vw;
    padding: 2rem;
    background-color: rgba(242, 244, 247, 1);
}

.referal-details > h2{
    font-size: 1.25vw;
    color: rgba(31, 170, 8, 1);
    font-weight: 500;
}

.referal-details > p{
    font-size: 1.042vw;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
}

.custom-table thead th {
    border: 1px solid transparent;
    padding: 10px;
    background-color: transparent;
    font-weight: 500;
    font-size: 1.042vw;
}

.custom-table tbody td {
    border: 1px solid transparent;
    padding: 10px;
    text-align: center;
    font-size: 0.938vw;
}

.custom-table tbody tr:nth-child(5n) td {
    border-bottom: 1px solid black;
}


.refer-reward{
    width: 85vw;
    margin: 0 auto 5% auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.reward-congrats{
    align-items: center;
    text-align: center;
    padding: 1% 0 5% 0;
    width: 36vw;
    height: fit-content;
    background-color: rgba(242, 244, 247, 1);
}

.reward-congrats > p{
    font-family: "Inter";
    font-size: 0.938vw;
    font-weight: 500;
    text-align: center;
    color: rgba(0, 0, 0, 1);    
}

.reward-congrats > h2{
    font-family: "Inter";
    font-size: 1.042vw;
    font-weight: 500;
    text-align: center;
    color: rgba(0, 0, 0, 1);  
    margin: 2% 0;  
}

.reward-congrats > button{
    background-color: rgba(31, 170, 8, 1);
    font-family: "Inter";
    font-size: 0.833vw;
    font-weight: 700;
    text-align: center;
    color: rgba(255, 255, 255, 1);    
    border: none;
    padding: 0.321vw 0.521vw;
    border-radius: 4px;
    cursor: pointer;
}




.reward-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.reward-modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding:  2%;
    border: 1px solid #888;
    width: 500px;
    border-radius: 5px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: 300;
    cursor: pointer;
    margin-bottom: 2rem;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
}

.reward-form > label {
    display: block;
    text-align: left;
    margin-top: 5%;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    
}

.reward-form > input {
    width: 90%;
    padding: 10px;
    background-color: rgba(242, 242, 242, 1);
    border: none;
    margin-left: -5%;
}

.congratulations {
    margin-top: 15px;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    
}

.reward-btn{
    background-color: rgba(31, 170, 8, 1);
    font-family: "Inter";
    font-size: 0.833vw;
    font-weight: 700;
    text-align: center;
    color: rgba(255, 255, 255, 1);    
    border: none;
    padding: 0.321vw 0.521vw;
    border-radius: 4px;
    cursor: pointer;
    margin: 4% 0 0 0;
}

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f3f4f6;
}

.congratulations-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40vw;
    background: linear-gradient(90deg, rgba(5, 41, 9, 1) 0%, rgba(31, 170, 8, 1) 100%);
    color: white;
    text-align: center;
}

.congratulations-card {
    background: rgba(255, 255, 255, 0.9);
    color: #333;
    padding: 2rem 3rem;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}


.congratulations-card > h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.congratulations-card > p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.hold-on-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
    background: linear-gradient(90deg, rgba(5, 41, 9, 1) 0%, rgba(31, 170, 8, 1) 100%);
    color: white;
    text-align: center;
}

.hold-on-card {
    background: rgba(255, 255, 255, 0.9);
    color: #333;
    padding: 2rem 3rem;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}


.hold-on-card > h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.hold-on-card > p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.go-back-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: white;
    background-color: rgba(31, 170, 8, 1);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.go-back-button:hover {
    transform: scale(1.15);
}


@media screen and (max-width: 768px) {
    .referal-top{
        margin: 2rem;
    }

    .referal-top > h1{
        font-size: 1.6rem;
    }
    
    .referal-top > h2{
        font-size: 1.5rem;
        width: 99%;
    }

    .refer-reward{
        flex-direction: column;
    }

    .referal-main-ctn{        
        width: 90vw;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    .referal-ctn{
        width: 100%;
        margin-bottom: 2rem;
    }

    .referal-ctn > p{
        font-size: 14px;
    }

    .referal-ctn > h1{
        font-size: 1.2rem;
    }

    .referal-ctn > h2{
        font-size: 1rem;
    }

    .referal-step-main1{
        margin: 0;
    }

    
    .referal-step-main1 > a{
        font-size: 13px;
        margin-top: 1rem;
    }

    .referal-step{
        width: 80vw;
        margin-left: -14vw;
    }


    .referal-step > img{
        width: 25px;
        height: 25px;
    }

    .referal-step > p {
        font-size: 14px;
    }

    .referal-step-main{
        padding: 1rem;
    }

    .referal-code{
        width: 100%;
        flex-direction: column;
        margin: 1rem auto;
        margin-left: -15%;
    }

    .referal-code > p{
        font-size: 1.3rem;
    }

    .refer-share{
        width: 59%;
        margin-top: 1rem;
        padding: 6px 1.2rem;
    }

    .refer-share > img{
        width: 22px;
        height: 27px;
    }

    .refer-share > p{
        font-size: 12px;
    }
    
    .referal-ctn1{
        width: 100%;
    }

    .referal-ctn1 > p{
        font-size: 14px;
    }

    .referal-ctn1 > h1{
        font-size: 1.2rem;
    }

    .referal-ctn1 > h2{
        font-size: 1rem;
    }

    

    .referal-details{
        margin: 2rem auto;
        width: 80%;
        padding: 1rem;
    }

    .referal-details > p{
        font-size: 14px;
    }

    .referal-details > h2{
        font-size: 1rem;
    }
    
    .custom-table {
        width: 100%;
    }

    .custom-table thead th {
        font-size: 15px;
    }

    .custom-table tbody td {
        font-size: 11px;
    }
    
    .reward-congrats{
        width: 100%;
    }

    .congrat-img{
        width: 254px;
        height: 109px;
    }

    .reward-congrats > p{
        font-size: 18px;
        font-weight: 500;
        width: 60%;
        margin: 1rem auto;
    }

    .reward-congrats > h2{
        font-size: 20px;
    }

    .reward-congrats > button{
        font-size: 14px;
        padding: 6px 15px;
        margin: 1.5rem 0 15px 0;
    }

    .wallet-img{
        width: 154px;
        height: 154px;
    }

    .reward-modal-content{
        width: 80%;
        height: auto;
        padding: 1rem;
    }

    .reward-modal-content > h2{
        margin-bottom: 2rem;
    }

    .reward-form > label{
        font-size: 16px;
        margin-bottom: 0.7em;
    }

    .reward-form > input{
        width: 85%;
        margin-left: -5vw;
        margin-bottom: 1.5rem;
    }

    .reward-btn{
        display: block;
        font-size: 14px;
        margin: 2rem auto;
        padding: 0.4rem 1.3rem;
    }

    .congratulations{
        font-size: 13px;
        margin-top: -1.5rem;
    }

    .referal-code-ctn > p{
        font-size: 14px;
        width: 100%;
    }

    .congratulations-page{
        width: 84vw;
        height: 50vh;
        margin: auto;
    }
    
    .congratulations-card {
        padding: 1.5rem;
        width: 75%;
    }
    
    .congratulations-card > h1 {
        font-size: 1.8rem;
    }
    
    .congratulations-card > p {
        font-size: 17px;
    }

    .congratulations-card > img{
        width: 100%;
    }
    
    
    
    .go-back-button {
        padding: 0.6rem 1rem;
        font-size: 0.9rem;
    }
    

}

