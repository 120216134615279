@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.footer-main-body{
    background-color: rgba(5, 41, 9, 1);
    margin: 0;
    padding: 0;

}

.footer-app-main{
    background-color: rgba(5, 41, 9, 1);
    height: fit-content;
    padding-bottom: 20px;
    padding-top: 30px;
    margin: 0;
}

.footer-title{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-title > img{
    margin-top: 1.043vw;
}

.footer-main-title > h3{
    font-family: "Montserrat";
    font-size: 1.51vw;
    padding: 0;
    margin: 0;
}

.footer-main-title > p{
    font-family: "Montserrat";
    font-size: 0.521vw;
    letter-spacing: 3px;
    color: rgba(255, 255, 255, 1);
}

.footer-main-title{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    line-height: normal;
    margin-left: 10px;
    margin-bottom: -23px;
    color: white;
}

.footer-col1 > p{
    font-family: "Inter";
    font-size: 1.042vw;
    font-weight: 500;
    text-align: left;
    color: white;
}
.footer-col1 > p > span{
    font-family: "Mochiy Pop One";
}


.footer-col{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


.footer-col1{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.footer-col2{
    display: flex;
    flex-direction: column;
}


.footer-col2 > h2 {
    font-family: "Inter";
    font-size: 1.042vw;
    font-weight: 700;
    text-align: left;
    color: white;

}

.col-links{
    display: flex;
    flex-direction: column;
}

.col-links > a {
    text-decoration: none;
    color: white;
    font-family: "Inter";
    font-size: 1.042vw;
    font-weight: 500;
    text-align: left;
    margin-top: 10px;
}

.footer-col2 > a {
    text-decoration: none;
    color: white;
    font-family: "Inter";
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
    margin-top: 10px;
}

.footer-col2 > p {
    text-decoration: none;
    color: white;
    font-family: "Inter";
    font-size: 1.042vw;
    font-weight: 500;
    margin: 2px;
    text-align: left;
}


.footer-app-main > hr {
    width: 85%;
    color: 5px solid white;
}

.footer-app-main > p {
    font-family: "Inter";
    color: white;
    font-size: 1.042vw;
    font-weight: 500;
    text-align: right;
    margin-right:7vw ;
}
.footer-icons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.footer-icons-pic{
    width: 1.563vw;
    height: 1.563vw;
    transition: transform 0.3s ease;

}


.footer-icons-pic:hover{
    transform: scale(1.35);
    transition-delay: 0.2s;
}

@media screen and (max-width: 768px) {
    .footer-main-body{
        background-color: rgba(5, 41, 9, 1);
        display: flex;
        flex-direction: column;
        margin: 0;
        overflow: hidden;
        word-wrap: break-word;
    }

    .footer-col{
        display: flex;
        flex-direction: column;
        margin-top: -50px;
        align-items: center;
        text-align: center;
        margin: 0;
    }

    
    .footer-col1{
        display: flex;
        flex-direction: column;
        width: 80vw;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        align-items: center;
    }

    .footer-col1 > p {
        text-align: center;
        font-size: 1rem;
        margin-top: 40px;
        font-weight: 300;
    }

    .footer-title{
        display: flex;
        flex-direction: row;
    }

    .footer-main-title > h3{
        font-family: "Inter";
        font-size: 1.7rem;
        padding: 0;
        margin: 0;
        text-align: left;
    }
    
    .footer-main-title > p{
        font-family: "Inter";
        font-size: 11px;
        line-height: 0;
        text-align: left;
    }
    
    .footer-title > img {
        width: 55px;
        height: 55px;
        margin-top: 20px;
    }

    .footer-col2 {
        display: flex;
        flex-direction: column;
        width: 80vw;
        margin-top: 40px;
    }

    .footer-col2 > h2 {
        text-align: center;
        font-size: 1.2rem;
    }
    
    .footer-col2 > p {
        text-align: center;
        font-size: 1.1rem;
    }

    .footer-col2 > a {
        text-align: center;
        font-size: 1.4rem;
    }

    .col-links{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .col-links > a{
        font-size: 15px;
    }


    .footer-icons {
        width: 60vw;
        display: flex;
        flex-direction: row;
        margin-right: auto;
        margin-left: auto;
        justify-content: space-between;
        margin-top: 100px;
    }
    
    .footer-app-main > hr {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .footer-app-main > p {
        font-family: "Inter";
        color: white;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 44px;
        text-align: center;
    }

    .footer-icons-pic{
        width: 2rem;
        height: 2rem;
    }

    .footer-app-main > p{
        font-size: 1rem;
    }
}



@media screen and (min-width: 900px) and (max-width: 1094px) {
    .footer-app-main {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        width: 90vw;
    }
    .footer-col1{
        width: 450px;
    }

    .footer-col2{
        display: flex;
        flex-direction: column;  
    }

    .col-links > a{
        font-size: 1.953vw;
    }

}