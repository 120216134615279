@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.review-main-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.review-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 92%;
}

.review-properties {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 100px;
  position: relative;
  /* background-color: red; */
}

.review-details1 {
  /* margin: 20px; */
  width: 85%;
}

.review-details1 > img {
  position: absolute;
  top: 1%;
  left: 1%;
  max-width: 10.417vw;
}

.review-details2 {
  width: 34vw;
  height: auto;
}

.review-details2-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.review-details1 > h2 {
  font-family: "Mochiy Pop One";
  font-size: 1.927vw;
  font-weight: 400;
  text-align: left;
  margin-top: 5.208vw;
  color: #222222;
}

.review-details1 > h2 > span {
  color: #1c8a09;
}

.review-details1 > p {
  font-family: "Inter";
  font-size: 1.406vw;
  font-weight: 400;
  text-align: left;
  color: #222222;
}

.review-details1 > p > span {
  color: #1c8a09;
  font-family: "Mochiy Pop One";
}

.review-select-box {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  margin-top: 80px;
}

.review-select-box1 > img {
  max-width: 18.333vw;
  max-height: 11.563vw;
}

.review-select-box1 > p {
  font-family: "Inter";
  font-size: 1.146vw;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  color: #222222;
}

.review-rating {
  max-width: 96vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 50px;
}

.review-rating1 > h2 {
  font-family: "Mochiy Pop One";
  font-size: 1.667vw;
  font-weight: 400;
  max-width: 36.458vw;
  color: #222222;
}

.review-rating1 > h2 > span {
  color: #1c8a09;
  font-family: "Mochiy Pop One";
}

.review-rating-details {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-around;
}

.review-rating2 {
  width: 26.042vw;
}

.review-rating3 {
  width: 26.042vw;
}

.review-rating-details1 > img {
  max-height: 19px;
}

.review-rating-details1 > h2 {
  font-family: "Mochiy Pop One";
  font-size: 1.667vw;
  font-weight: 400;
  margin-bottom: -1px;
  color: #222222;
}

.nigeria-flag {
  max-width: 7.813vw;
  height: 10px;
  background-color: #308c3c;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
}

.nigeria-white {
  background-color: white;
  width: 50px;
  height: 10px;
}

.review-rating-details1 > p {
  font-family: "Inter";
  font-size: 1.042vw;
  font-weight: 600;
  margin-top: -1px;
  color: #222222;
}

.review-rating-details2 > img {
  display: none;
}

.review-rating-details2 > h2 {
  font-family: "Mochiy Pop One";
  font-size: 1.667vw;
  font-weight: 400;
  color: #222222;
}

.review-rating-details2 > p {
  font-family: "Inter";
  font-size: 1.042vw;
  font-weight: 600;
  margin-top: -1.15vw;
}

.review-trust {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  width: 99%;
  margin-top: 10px;
  margin-bottom: 100px;
  /* background-color: red; */
}

.review-trust1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: fit-content;
}

.review-trust1-picture {
  width: 23.333vw;
  height: auto;
  transition: transform 0.3s ease;
}

.review-trust1-picture > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.review-trust2-picture {
  width: 23.333vw;
  height: auto;
  transition: transform 0.3s ease;
  margin-top: 4vh;
}

.review-trust1-picture:hover {
  transform: scale(0.75);
  transition-delay: 0.2s;
}

.review-trust2-picture:hover {
  transform: scale(0.75);
  transition-delay: 0.2s;
}

.review-trust2-picture > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.review-trust-main-video {
  width: 22.5vw;
  height: 37vw;
  background-color: gray;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.review-trust-video-frame {
  height: 100%;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  object-fit: cover;
  object-fit: fill;
  position: relative;
}

.review-playicon {
  /* transform: translate(-50%, -50%); */
  bottom: 3%;
  left: 7%;
  position: absolute;
  width: 45px;
  height: 45px;
}

.review-trust-sub-videos-main {
  display: flex;
  justify-content: flex-end;
  margin-left: 10rem;
  height: 4rem;
  flex-direction: row;
  overflow: hidden;
  position: absolute;
  bottom: 5%;
}

.review-videos-sub-profile {
  margin: 0;
  padding-left: 20px;
  gap: 0;
}

.review-vidoes-sub-profile-name {
  padding: 0;
  font-weight: 600;
}

.review-videos-sub-profile-occupation {
  padding: 0;
}

.review-trust-sub-video {
  position: relative;
  width: 50px;
  height: 50px;
  margin-left: -12px;
  cursor: pointer;
  z-index: 999;
  display: flex;
}

.review-trust-sub-video:hover {
  z-index: 1;
}

.review-trust-sub-video > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.3s ease;
}

.review-trust-sub-video:hover > img {
  transform: scale(1.2);
}

.review-trust3 {
  width: 25vw;
  height: 37.333vw;
  background-color: #1faa08;
  position: relative;
  border-radius: 30px;
}

.review-trust3-picture {
  width: 21.479vw;
  height: 12.24vw;
  left: 30px;
  /* margin-top: 76px; */
  object-fit: contain;
  position: absolute;
  top: 10%;
  right: 0%;
}

.review-trust3-picture > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.review-trust3-props {
  position: absolute;
  top: 50%;
  left: 10%;
}

.review-trust3-props > h2 {
  font-family: "Mochiy Pop One";
  font-size: 1.24vw;
  font-weight: 400;
  line-height: 47px;
  text-align: left;
  color: white;
  width: 80%;
}

.review-trust3-props > p {
  font-family: "Inter";
  font-size: 1.042vw;
  font-weight: 500;
  line-height: 47px;
  text-align: left;
  color: white;
}

.review-trust3-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  width: 11.333vw;
}

.review-trust3-icons-pic {
  width: 1.667vw;
  height: 1.667vw;
  transition: transform 0.3s ease;
}

.review-trust3-icons-pic:hover {
  transform: scale(1.5);
  transition-delay: 0.2s;
}

.review-support {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 60px;
}

.review-map2 {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-left: 5%;
  /* width: 44%; */
}

.review-map-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.review-map-logo > img {
  max-width: 80px;
  margin-top: 20px;
  margin-right: 15px;
}

.review-map-content > h2 {
  font-family: "Mochiy Pop One";
  font-size: 1.667vw;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #222222;
}

.review-map-content > h2 > span {
  color: #308c3c;
}

.review-map-content > p {
  font-family: "Inter";
  font-size: 1.067vw ;
  font-weight: 500;
  text-align: left;
  color: #222222;
  margin-top: 1.104vw;
}

.review-map-content > p > span {
  color: #308c3c;
}

.review-download-button {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.review-download-btn {
  border: none;
  border-radius: 6px;
  width: 12.5vw;
  height: auto;
  /* height: 3rem; */
  object-fit: contain;
  margin-right: 20px;
  cursor: pointer;
}

.review-download-button > button {
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}

.review-portrait-pic {
  display: none;
}

.rev-portrait {
  display: none;
}

@media screen and (min-width: 350px) and (max-width: 780px) {
  .review-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .review-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .review-properties {
    display: flex;
    flex-direction: column;
  }

  .review-details1 > img {
    display: none;
  }

  .review-details1 {
    text-align: center;
  }

  .review-select-box {
    display: flex;
    flex-direction: column;
  }

  .review-select-box1 > img {
    max-width: 1100vw;
    max-height: 25vh;
    box-shadow: 0px 2.94px 11.98px 0px #00000040;
  }

  .review-select-box1 > p {
    font-size: 1.25rem;
  }

  .review-rating {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .review-rating1 > h2 {
    font-size: 1.5rem;
    text-align: center;
    justify-content: center;
    margin: 0 6vw;
  }

  .review-rating3 {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }

  .review-rating2 {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }

  .review-trust {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  .review-trust1 {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  .review-trust2 {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    margin-top: -30px;
  }

  .review-trust3 {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    margin-top: 30px;
    width: 18rem;
    height: 28rem;
  }

  .review-trust1-picture {
    width: 19.5rem;
    height: 14rem;
  }

  .review-trust1-picture > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .review-trust2-picture {
    width: 19.5rem;
    height: 14rem;
    margin-top: 2rem;
  }

  .review-trust1 > img {
    margin-bottom: 40px;
    max-width: 70vw;
  }

  .review-trust-main-video {
    width: 16rem;
    height: 28rem;
    border-radius: 30px;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .review-trust-sub-videos-main {
    margin-top: 24rem;
    margin-right: 2rem;
  }

  .review-trust3-picture {
    display: none;
  }

  .rev-portrait {
    display: block;
    width: 16rem;
    height: 7rem;
    position: absolute;
    top: 10%;
    right: 0%;
  }

  .review-trust3-props {
    position: absolute;
    top: 40%;
    left: 10%;
  }

  .review-trust3-props > h2 {
    font-family: "Mochiy Pop One";
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 47px;
    text-align: left;
    color: white;
  }

  .review-trust3-props > p {
    font-family: "Inter";
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 47px;
    text-align: left;
    color: white;
  }

  .review-trust3-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    width: 10rem;
  }

  .review-trust3-icons-pic {
    width: 2rem;
    height: 2rem;
  }

  .review-support {
    display: flex;
    flex-direction: column;
  }

  .review-map2 {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .review-map > img {
    max-width: 90vw;
  }

  .review-map-content {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .review-map-content > h2 {
    font-size: 24px;
    text-align: center;
    max-width: 90vw;
    line-height: 1px;
  }

  .review-map-content > p {
    font-size: 18px;
    text-align: center;
    max-width: 80vw;
    margin-top: 40px;
  }

  .review-download-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
  }

  .review-download-button > button {
    max-height: 16vh;
  }

  .review-details2 {
    display: none;
  }

  .review-details1 > h2 {
    font-size: 25px;
    text-align: center;
    max-width: 90vw;
  }

  .review-details1 > p {
    font-size: 20px;
    text-align: center;
    max-width: 90vw;
  }

  .review-rating-details1 > h2 {
    font-size: 26px;
  }

  .review-rating-details1 > img {
    width: 4rem;
  }

  .review-rating-details1 > p {
    font-size: 18px;
  }

  .review-rating-details2 > h2 {
    font-size: 26px;
  }

  .review-rating-details2 > img {
    width: 4rem;
  }

  .review-rating-details2 > p {
    font-size: 18px;
  }

  .nigeria-flag {
    width: 6rem;
    height: 10px;
    background-color: #308c3c;
    flex-direction: row;
    justify-content: center;
    margin: 7px;
  }

  .nigeria-white {
    background-color: white;
    width: 2rem;
  }

  .review-portrait-pic {
    max-width: 10rem;
    max-height: 10rem;
    /* background-color: blue; */
  }

  .review-download-button {
    display: flex;
    flex-direction: row;
    border: none;
  }

  .review-download-btn {
    width: 9rem;
    height: 3rem;
  }

  .review-portrait {
    width: 20rem;
    height: 21rem;
    display: flex;
    justify-content: center;
    margin-bottom: -50px;
  }

  .review-portrait-pic {
    display: block;
    max-height: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1022px) {
  .review-portrait {
    margin-left: 25%;
  }

  .review-select-box {
    display: flex;
    flex-direction: row;
    margin-bottom: -5rem;
  }

  .review-trust {
    display: grid;
    grid-template-areas:
      "review-trust1"
      "review-trust-main-video review-trust3";
    width: 90%;
    height: 80vh;
    margin: 2rem auto;
  }

  .review-trust1 {
    grid-area: review-trust1;
    display: flex;
    flex-direction: row;
    margin-bottom: 50rem;
  }

  .review-trust-main-video {
    grid-area: review-trust-main-video;
  }

  .review-trust3 {
    grid-area: review-trust3;
    margin-left: 20rem;
  }

  .review-trust1-picture {
    height: 13rem;
  }

  .review-trust2-picture {
    margin: 0;
    height: fit-content;
  }
}
