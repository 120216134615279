@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.service-main{
    background-color: rgba(249, 249, 250, 1);
}


.service-container{
    display: flex;
    flex-direction: column;
    background: rgba(249, 249, 250, 1);
    margin-bottom: 3rem;
}

.service-container > h1{
    font-family: "Montserrat";
    font-size: 50px;
    font-weight: 700;
    letter-spacing: -0.06em;
    text-align: center;
}

.service-content{
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin-right: auto;
    margin-left: auto;
}

.service-details > h2 {
    font-family: "Montserrat";
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: -0.06em;
}

.service-details > p {
    font-family: "Inter";
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
}



@media screen and (max-width: 900px) {
    .service-main {
      margin: 0 auto; /* Center align the container */
      text-align: center;
    }

    .service-container{
        display: flex;
        flex-direction: column;
    }

    .service-container > h1{
        font-family: "Montserrat";
        font-weight: 700;
        letter-spacing: -0.06em;
        text-align: center;
        font-size: 35px;
    }

    .service-details > h2 {
        font-family: "Montserrat";
        font-size: 25px;
        font-weight: 700;
        text-align: center;
    }
    
    .service-details > p {
        font-family: "Inter";
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }
    


}