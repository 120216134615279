@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.operation-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.operation-container {
  display: flex;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: fit-content;
  justify-content: center;
}

.operation-container > img {
  width: 100%;
  height: 55vw;
  display: flex;
  justify-content: center;
  /* margin-top: -3rem; */
  object-fit: contain;
}

.operation-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 80%;
  /* margin-top: -6.7rem !important; */
  padding-bottom: 80px;
}

.operation-content > p {
  font-family: "Inter";
  font-size: 1.302vw;
  font-weight: 500;
  text-align: center;
  line-height: 150%;
}

.operation-content > p > span {
  color: #308c3c;
}

.operation-content > h2 {
  font-family: "Mochiy Pop One";
  font-size: 2.031vw;
  font-weight: 400;
  text-align: center;
  margin-top: 8%;
}

.operation-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 11rem; */
}

.operation-image > img {
  max-height: 1.354vw;
}

.operation-img1 {
  width: 22.5vw;
  height: 15.5vw;
  border: 0.29px solid whitesmoke;
  padding: 6% 0 3% 0;
  justify-content: center;
  box-shadow: 0.1px 1.09px 5.58px 0.1px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 33px;
  border-bottom-right-radius: 33px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.operation-img1 h2 {
  font-family: "Mochiy Pop One";
  font-size: 1vw;
  font-weight: 600;
  margin-top: -7%;
  text-align: center;
  color: #000000;
}

.operate-img-par {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

.operation-potrait {
  display: none;
}

@media screen and (min-width: 350px) and (max-width: 768px) {
  .operation-container {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .operation-potrait {
    display: block;
    margin-left: -2vw;
    width: 94vw;
    margin-bottom: 70px;
    height: fit-content;
    object-fit: contain;
  }

  .operation-container > img {
    display: none;
  }
  .operation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -7%;
    width: 80%;
  }

  .operation-content > p {
    font-size: 16px;
    word-wrap: break-word;
    width: 100%;
    margin-left: 5vw;
  }

  .operation-content > h2 {
    font-size: 1.4rem;
    margin: 15% 0 0 0;
  }

  .operation-image {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
  }

  .operation-img1 {
    width: 10rem;
    height: auto;
    padding: 1rem;
    border: 0.29px solid #1faa08;
    box-shadow: 0.7px 0.9px 0.9px 0.9px rgba(0, 0, 0, 0.25);
    margin: 1rem 0;
  }

  .operation-img1 > h2 {
    font-family: "Mochiy Pop One";
    font-size: 0.7rem;
    font-weight: 600;
    text-align: center;
    color: #000000;
  }

  .operate-img-par {
    width: 100%;
    height: 10rem;
    object-fit: cover;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .operation-container > img {
    width: 94%;
    height: auto;
    margin-top: 0rem;
    display: block;
  }

  .operation-potrait {
    display: none;
  }

  .operation-content > h2 {
    font-size: 1.2rem;
    /* margin-top: -5rem; */
  }

  .operation-content {
    width: 80%;
  }

  .operation-content > p {
    font-size: 1rem;
    line-height: 140%;
    width: 100%;
    padding: 0;
  }

  .operation-image {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto;
  }

  .operate-img-par {
    width: auto;
    height: 100px;
    object-fit: contain;
    margin-left: -0.6rem;
  }

  .operation-img1 {
    width: 7.813vw;
    height: 6.25vw;
  }

  .operation-img1 > h2 {
    font-size: 0.977vw;
  }
}
