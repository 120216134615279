@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.testimonial-main-container {
  height: fit-content;
}

.testimonial-container {
  display: flex;
  flex-direction: column;
  max-width: 95%;
  margin: 0 auto;
  /* background-color: red; */
}

.testimonial-col-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.testimonial-col-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.testimonial-box1 {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-right: 5%;
}

.testimonial-box1 > img {
  margin-left: 90px;
}

.container1 {
  /* width: 524px; */
  width: 100%;
  height: auto;
  background-color: white;
  padding: 21px;
  transition: transform 0.3s ease;
}

.profile-details1 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
}

.test-profile-about1 {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-left: 10px;
  margin-top: -15px;
}

.test-profile-about1 > h2 {
  font-family: "Inter";
  font-size: 1.354vw;
  font-weight: 600;
  text-align: left;
  margin-bottom: -10px;
}

.test-profile-about1 > p {
  font-family: "Inter";
  font-size: 1.042vw;
  font-weight: 500;
  text-align: left;
}

.container1 > p {
  font-size: 1.042vw;
  font-family: "Inter";
  /* font-size: 24px; */
  font-weight: 500;
  text-align: left;
}

.youtube-icon1 {
  position: absolute;
  top: 0;
  right: 0;
}

.container2 {
  width: 66%;
  height: fit-content;
  margin-right: 10px;
  background-color: white;
  padding: 28px;
  margin-top: 40px;
  transition: transform 0.3s ease;
}

.profile-details2 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
}

.test-profile-about2 {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-left: 10px;
  margin-top: -15px;
}

.test-profile-about2 > h2 {
  font-family: "Inter";
  font-size: 1.342vw;
  font-weight: 600;
  text-align: left;
  margin-bottom: -10px;
}

.test-profile-about2 > p {
  font-family: "Inter";
  font-size: 1.042vw;
  font-weight: 500;
  text-align: left;
}

.container2 > p {
  font-size: 1.042vw;
  font-family: "Inter";
  /* font-size: 24px; */
  font-weight: 500;
  text-align: left;
}

.youtube-icon2 {
  position: absolute;
  top: 0;
  right: 0;
}

.container3 {
  width: 76%;
  height: fit-content;
  margin-left: 30px;
  margin-right: 30px;
  background-color: white;
  padding: 25px;
  margin-top: 70px;
  transition: transform 0.3s ease;
}

.profile-details3 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
}

.test-profile-about3 {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-left: 10px;
  margin-top: -15px;
}

.test-profile-about3 > h2 {
  font-family: "Inter";
  font-size: 1.342vw;
  font-weight: 600;
  text-align: left;
  margin-bottom: -10px;
}

.test-profile-about3 > p {
  font-family: "Inter";
  font-size: 1.042vw;
  font-weight: 500;
  text-align: left;
}

.container3 > p {
  font-size: 1.042vw;
  font-family: "Inter";
  /* font-size: 24px; */
  font-weight: 500;
  text-align: left;
}


.container5 {
  width: 28vw;
  height: 400px;
  padding: 25px;
  margin-left: 20px;
  transition: transform 0.3s ease;
}

.profile-details5 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
}



.test-profile-about5 {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-left: 10px;
  margin-top: -15px;
}

.test-profile-about5 > h2 {
  font-family: "Inter";
  font-size: 1.342vw;
  font-weight: 600;
  text-align: left;
  margin-bottom: -10px;
}

.test-profile-about5 > p {
  font-family: "Inter";
  font-size: 1.042vw;
  font-weight: 500;
  text-align: left;
}

.container5 > p {
  font-size: 1.042vw;
  font-family: "Inter";
  /* font-size: 24px; */
  font-weight: 500;
  text-align: left;
}

.youtube-icon5 {
  position: absolute;
  top: 0;
  right: 0;
}

.container4 {
  width: 28%;
  height: fit-content;
  margin-left: 10px;
  background-color: white;
  padding: 20px;
}

.profile-details4 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
}

.test-profile-about4 {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-left: 10px;
  margin-top: -15px;
}

.test-profile-about4 > h2 {
  font-family: "Inter";
  font-size: 1.342vw;
  font-weight: 600;
  text-align: left;
  margin-bottom: -10px;
}

.test-profile-about4 > p {
  font-family: "Inter";
  font-size: 1.042vw;
  font-weight: 500;
  text-align: left;
}

.container4 > p {
  font-size: 1.042vw;
  font-family: "Inter";
  /* font-size: 24px; */
  font-weight: 500;
  text-align: left;
}

.youtube-icon4 {
  position: absolute;
  top: 0;
  right: 0;
}

.container6 {
  width: 27%;
  height: fit-content;
  background-color: white;
  padding: 20px;
  margin-left: 20px;
  margin-top: 20px;
  transition: transform 0.3s ease;
}
.profile-details6 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 30rem;
}

.test-profile-about6 {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-left: 10px;
  margin-top: -15px;
}

.test-profile-about6 > h2 {
  font-family: "Inter";
  font-size: 1.342vw;
  font-weight: 600;
  text-align: left;
  margin-bottom: -10px;
}

.test-profile-about6 > p {
  font-family: "Inter";
  font-size: 1.042vw;
  font-weight: 500;
  text-align: left;
}

.container6 > p {
  font-size: 1.042vw;
  font-family: "Inter";
  /* font-size: 24px; */
  font-weight: 500;
  text-align: left;
}

.youtube-icon6 {
  position: absolute;
  top: 0;
  right: 0;
}

.ellipse-icon {
  width: 3.646vw;
  height: 3.646vw;
}

.testimonial-icon {
  width: 9.583vw;
  height: 4.375vw;
}

.rate-icon {
  height: 1.354vw;
  object-fit: contain;
}

/* testimonial videos */
.testimonial-videos-parent {
  width: 33%;
  height: 25vw;
  margin: 2%;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0px 3.09px 12.58px 0px rgba(0, 0, 0, 0.25);
}

.testimonial-video-frame {
  height: 100%;
  width: 90%;
  /* position: absolute; */
  border-radius: 30px;
  overflow: hidden; /* Ensure content within the container doesn't overflow. Without this property the border radius wont take effect */
  margin: 0;
  /* background-color: grey; */
  margin: 20px 0;
  object-fit: cover;
  /* margin: 20px; */
  object-fit: fill;
}

.testimonial-video-playicon {
  transform: translate(-20%, -20%);
  width: 45px;
  height: 45px;
}

.testimonial-videos-sub-parent {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px 20px 60px;
  width: 90%;
  /* background-color: red; */
}
.testimonial-videos-sub-profile {
  padding-left: 20px;
  margin: 0;
  gap: 0;
}
.testimonial-videos-sub-profile-name {
  margin: 0;
  font-weight: 600;
  /* background-color: red; */
}
.testimonial-videos-sub-profile-occupation {
  padding: 0;
  /* background-color: blue; */
}
.testimonial-video-sub {
  position: relative;
  width: 50px;
  /* height: 50px; */
  margin-left: -12px;
  cursor: pointer;
  object-fit: cover;
}


.testimonial-video-sub:hover {
  z-index: 1;
}
.testimonial-video-sub img {
  width: 100%;
  height: 48px;
  border-radius:  50%;
  transition: transform 0.3s ease;
}

.testimonial-video-sub:hover img {
  transform: scale(1.2); /* Adjust the value as needed */
}
/* url='https://vimeo.com/243556536' */

@media screen and (min-width: 350px) and (max-width: 780px) {
  .testimonial-main-container{
    width: 100vw;
    margin: 0;
    padding: 0;
  }
   
  .testimonial-container {
    display: flex;
    overflow: hidden;
    justify-content: center;
    padding-bottom: 1vh;
    /* margin: 0 auto; */
  }

  .testimonial-col-1 {
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding-bottom: 2vh;
  }

  .testimonial-col-2 {
    display: flex;
    flex-direction: column;
    /* margin: 0;
    padding: 0; */
    margin-top: 2vh;
    padding-bottom: 2vh;
  }

  .testimonial-box1 {
    width: 20rem;
    height: 20rem;
    margin: 0;
    padding: 4px;
    margin-left: -4rem;
    margin-bottom: -8vh;
  }

  .testimonial-icon {
    width: 7rem;
    height: 3rem;
  }

  .container1 {
    width: 17rem;
    height: fit-content;
    margin-left: 10%;
  }

  .container1 > p {
    font-size: 0.9rem;
  }

  .test-profile-about1 > p {
    font-size: 0.8rem;
  }

  .test-profile-about1 > h2 {
    font-size: 0.9rem;
  }

  .container2 {
    width: 17rem;
    height: fit-content;
    margin-bottom: -4vh;
  }

  .container2 > p {
    font-size: 0.9rem;
  }

  .test-profile-about2 > p {
    font-size: 0.8rem;
  }

  .test-profile-about2 > h2 {
    font-size: 0.9rem;
  }

  .container3 {
    width: 17rem;
    height: fit-content;
  }

  .container3 > p {
    font-size: 0.9rem;
  }

  .test-profile-about3 > p {
    font-size: 0.8rem;
  }

  .test-profile-about3 > h2 {
    font-size: 0.9rem;
  }

  .container4 {
    width: 17rem;
    height: fit-content;
  }

  .container4 > p {
    font-size: 0.9rem;
  }

  .test-profile-about4 > p {
    font-size: 0.8rem;
  }

  .test-profile-about4 > h2 {
    font-size: 0.9rem;
  }

  .container6 {
    width: 17rem;
    height: fit-content;
  }

  .container6 > p {
    font-size: 0.9rem;
  }

  .test-profile-about6 > p {
    font-size: 0.8rem;
  }

  .test-profile-about6 > h2 {
    font-size: 0.9rem;
  }

  .container5 {
    width: auto;
    height: fit-content;
    margin-top: 5vh;
  }

  .ellipse-icon {
    width: 2rem;
    height: 2rem;
  }

  .rate-icon {
    width: 5rem;
    height: 1rem;
  }

  .testimonial-videos-parent{
    width: 20rem;
    height: 18rem;
    background-color: white;
    margin: 2rem 0;
    box-shadow: 0px 3.09px 12.58px 0px rgba(0, 0, 0, 0.25);
  }

  .testimonial-video-playicon {
    transform: translate(-20%, -20%);
    width: 30px;
    height: 30px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .testimonial-container{
    display: flex;
    flex-direction: column;
  }

  .testimonial-box1{
    margin-right: 5%;
  }
  .container2{
    margin-left: 5%;
  }

}