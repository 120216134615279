@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.tutorial-main-container{
    width: 100%;
    height: fit-content; 
    background-image: url('../../images/basicset.webp');
    background-size: cover;
    background-position: center;
    color: white;
    padding-bottom: 60px;
}

.tutorial-container{
    display: flex;
    flex-direction: column;
    /* width: 70%; */
    margin-right: auto;
    margin-left: auto;
}

.tutorial-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.rotated-text{
    transform: rotate(-18deg); /* Rotate text 90 degrees clockwise */
    color: rgba(242, 225, 26, 1);
    font-family: "Mochiy Pop One";
    font-size: 1.302vw;
    font-weight: 400;
    margin-left: -10%;
    margin-bottom: 10px;
}

.tutorial-header > h2{
    font-family: "Mochiy Pop One";
    font-size: 2.188vw  ;
    font-weight: 400;
    line-height: 38px;
    text-align: center;
    margin-left: -70px;
    margin-top: 150px;
}

.header-bar{
    margin-left: 70px;
}

.tutorial-header > h2 > span {
    color: rgba(31, 170, 8, 1);
}

.video-main-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Mochiy Pop One";
    font-size: 1.146vw  ;
    font-weight: 400;
    text-align: center;
    margin: 0 auto;

}

.video-sub-container{
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}

.video-container1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
}

.video-container2{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.video-container-props {
    width: 13.333vw; 
    height: 12.5vw;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border: 1px solid black;
}

.thumbnail-container{
    width: 15vw;
    height: 14.063vw;
}

.thumbnail{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.play-icon{
    width: 2.5vw;
    height: 2.5vw;
    position: absolute;
    top: 40%;
    left: 40%;
}

.play-btn{
    width: 100%;
    height: 100%;
}

.video-play{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 15vw; 
    height: 13.333vw;
    border-radius: 40px;
    overflow: hidden;
}

.tutorial-videos{
    width: 20%;
    height: 10%;
    border-radius: 50px;
    object-fit: contain;
}

.fullscreen-btn{
    width: 1.5rem;
    height: 1.5rem;
    background-color: transparent;
    border: none;
    position: absolute;
    bottom: 5%;
    right: 10%;
    cursor: pointer;
}

.video-details > p{
    font-size: 1.042vw;
}


.video-details1 > p{
    font-size: 1.042vw;
}

.video-details2 >p{
    font-size: 1.042vw;
}


.video-details3 > p{
    font-size: 1.042vw;
}

.video-details4 > p{
    font-size: 1.042vw;
}

.video-details5 > p{
    font-size: 1.042vw;
}

.video-details7 > p{
    font-size: 1.042vw;
}



.video-details1{
    margin: 0 2rem;
}

.video-details4{
    margin: 0 2rem;
}

.video-details1 > p{
    font-size: 1.042vw;
}

.video-details7{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    margin-top: -40px;
    width: 20.833vw;
    height: 44.6rem;
}

.vector-class-icon{
    width: 20rem;
    margin-left: 100px;
    margin-bottom: -40px;
}

.vector-tutorial-icon{
    margin-bottom: -100px;
    transform: rotate(10deg);
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.video-container-props7{
    width: 19rem; 
    height: 22.5rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 50px;
}

.thumbnail-container7{
    width: 19rem; 
    height: 22.5rem;
    object-fit: contain;
}

.thumbnail7{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.play-icon7{
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 40%;
    left: 40%;
}

.play-btn7{
    width: 100%;
    height: 100%;
}

.video-play7{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 19rem; 
    height: 33rem;
    border-radius: 40px;
    overflow: hidden;
}

.tutorial-videos7{
    width: 20%;
    height: 10%;
    border-radius: 50px;
    object-fit: contain;
}

.fullscreen-btn7{
    width: 1.5rem;
    height: 1.5rem;
    background-color: transparent;
    border: none;
    position: absolute;
    bottom: 5%;
    right: 10%;
    cursor: pointer;
}

.pause-icon{
    width: 1.5rem;
    height: 1.5rem;
    color: black;
    position: absolute;
    bottom: 7%;
    right: 20%;
}

@media screen and (min-width: 350px) and (max-width: 780px) {
    .tutorial-main-container{
        display: flex;
        flex-direction: column;
        margin: 0;
        overflow: hidden;
        height: fit-content;
        padding-bottom: 7rem;
    }
    .tutorial-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .tutorial-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        padding-top: 40px;
    }
    
    .tutorial-header > h2{
        font-family: "Mochiy Pop One";
        font-size: 1.3rem;
        font-weight: 400;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        margin-top: 40px;
        
    }
    
    .rotated-text{
        transform: rotate(-23deg); /* Rotate text 90 degrees clockwise */
        color: rgba(242, 225, 26, 1);
        font-family: "Mochiy Pop One";
        font-size: 1.25rem;
        font-weight: 400;
        margin-left: 0;
        margin-bottom: 40px;
        margin-top: 20px;
        margin-left: -19vw;
    }

    .video-main-container{
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .video-sub-container{
        width: auto;
    }

    .video-container1{
        width: 100%;
        margin-top: -20%;
    }

    .thumbnail-container{
        width: 100%;
        height: 100%;
    }

    .video-details{
        margin: 3% 0;
        width: 100%;    
    }

    .thumbnail{
        width: 100%;
        height: 100%;
    }

    .video-details1{
        margin: 3% 0;
        width: 100%;
    }

    .video-details2{
        margin: 3% 0;
        width: 100%;
    }

    .video-details3{
        margin: 3% 0;
        width: 100%;
    }

    .video-details4{
        margin: 3% 0;
        width: 100%;
    }

    .video-container-props{
        margin: 0 auto;
    }

    .video-container{
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .video-container1{        
        display: flex;
        flex-direction: column;
    }

    .video-container2{
        display: flex;
        flex-direction: column;
    }

    .play-icon{
        width: 50px;
        height: 50px;
    }


    .vector-tutorial-icon{
        top: 0;
        right: 0;
        width: 175px;
        height: 130px;
        transform: rotate(10deg);
        margin-bottom: 4px;
    }

    .video-details1 > p {
        font-size: 1.10rem;
    }

    .video-details2 > p {
        font-size: 1.10rem;
    }

    .video-details > p {
        font-size: 1.10rem;
    }
    
    .video-details3 > p {
        font-size: 1.10rem;
    }
 
    .video-details4 > p {
        font-size: 1.10rem;
    }
    
    .video-details5 > p {
        font-size: 1.10rem;
    }

    .video-detailss6 > p {
        font-size: 1.10rem;
    }

    .order-overlay-image{
        width: 7rem;
        height: 10rem;
    }

    .video-details7{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 17rem;
        height: 32rem;
        margin-top: 60px;
        
    }

    .video-tutorial-one{
        width: 100%;
        height: 100%;
    }
    
    .video-details7 > p {
        font-size: 1.10rem;
    }

    .vector-tutorial-icon {
        width: 10.8rem;
        height: 7.5rem;
        margin-bottom: 1rem;
        margin-top: -12rem;
    }

    .video-container-props{
        width: 100%;
        height: 75vw;
    }

    .tutorial-videos{
        width: 100%;
        height: 100%;
    }

    .video-play{
        width: 100%;
        height: 100%;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1094px) {
        .tutorial-container{
        width: 100%;
    }

    .tutorial-videos{
        width: 80%;
        height: 90%;
    }

    .tutorial-header{
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: left;
    }

    .rotated-text{
        margin-left: 20px;
    }

    .tutorial-header > h2{
        text-align: center;
        margin-left: -50px;
    }

    .video-main-container{
        width: 95%;
    }

    .video-container1{
        display: flex;
        flex-direction: row;
    }

    .video-container2{
        display: flex;
        flex-direction: row;
    }

    .video-container-props{
        width: 19.167vw;
        height: 17.167vw;
        border: transparent;
        border-radius: none;
    }
    
    .tutorial-videos{
        width: 100%;
        height: 100%;
    }

    .video-play{
        width: 100%;
        height: 100%;
    }

    .thumbnail-container{
        width: 100%;
        height: 100%;
    }
    
    
    .play-icon{
        width: 30px;
        height: 30px;
    }
    

    .video-details > p{
        font-size: 1rem;
    }

    .video-details1 > p{
        font-size: 1rem;
    }

    .video-details2 > p{
        font-size: 1rem;
    }

    .video-details3 > p{
        font-size: 1rem;
    }

    .video-details4 > p{
        font-size: 1rem;
    }

    .video-details5 > p{
        font-size: 1rem;
    }

    .video-container-props7{
        width: 22.167vw;
        height: auto;
        border-radius: 1px;
    }
    
    .tutorial-videos7{
        width: 100%;
        height: auto;
    }

    .video-play7{
        width: 100%;
        height: 100%;
    }

    .thumbnail-container7{
        width: 100%;
        height: 100%;
    }

    .thumbnail7{
        width: 100%;
        height: 100%;
    }
    
    .play-icon7{
        width: 50px;
        height: 50px;
    }
    
    .video-details7 > p{
        font-size: 1rem;
    }
    
}