@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.question-container{
    position: relative;
    width: 100%;
    height: fit-content; 
    background-image: url('../../images/blackbg.jpg');
    background-position: center;
    /* padding: 0 50px; */
}

.question-top-left-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 32.031vw;
    height: 12.812vw;
    z-index: 0;
}
  
.question-bottom-right-image {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 10px;
    width: 28.802vw;
    height: 21.771vw;
}

.squiggly-top-left-image{
    display: none;
}

.squiggly-bottom-right-image{
    display: none;
}
  
.question-main-container{
    font-family: "Mochiy Pop One";
    display: flex;
    flex-direction: column;
}

.asked-main-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 100px;
    padding: 0 40px;

}

.asked-container1{
    width: 37.76vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.asked-container2{
    width: 37.76vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 40px;

}


.question-main-container > h1 {
    font-family: "Mochiy Pop One";
    font-size: 1.667vw;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: rgba(249, 249, 250, 1);
    text-align: center;
    padding-top: 80px;
    padding-bottom: 50px;
    z-index: 1;
}

.questions-about {
    margin: 1.563vw;
}

.questions-about > h2 {
    font-family: "Mochiy Pop One";
    font-size: 1.142vw;
    font-weight: 400;
    text-align: left;
    color: rgba(143, 255, 158, 1);
    z-index: 1;
}

.questions-about > p {
    font-family: "Mochiy Pop One";
    font-size: 1.042vw;
    font-weight: lighter;
    text-align: left;
    color: rgba(249, 249, 250, 1);
    z-index: 1;
}

.question-btn {
    /* width: 250px; */
    padding: 0.885vw 1.823vw; 
    border: 4px solid white; 
    border-radius: 1.042vw; 
    background-color: transparent;
    color: white; 
    font-size: 1.142vw; 
    font-weight: 500;
    cursor: pointer; 
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2.083vw;
    text-decoration: none;
    transition: all .3s ease;
    /* margin-top: 40px; */
  }
  
  .question-btn:hover {
    background-color: white; 
    color: black; 
  }
  
@media screen and (min-width: 350px) and (max-width: 780px) {
    .question-top-container{
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    .question-container{
        display: flex;
        flex-direction: column;
    }  
    
    .question-main-container{
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: auto;
    }

    .question-main-container > h1{
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;        
    }

    .asked-container1{
        width: 100%;
        margin-top: -60px;
    }

    .asked-container2{
        width: 100%;
        padding: 0;
    }

    .asked-main-container{
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .questions-about > h2{
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 34px;
        text-align: left;
        margin-top: 14%;
    }

    .questions-about > p {
        font-size: 14px;
        font-weight: 300;
        text-align: left;
        margin-bottom: -10px;
    }

    .question-top-left-image{
        position: absolute;
        top: 0;
        left: 0;
        width: 6rem;
        height: 5rem;
    }

    .question-bottom-right-image{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 6rem;
        height: 5rem;
    }

    .question-btn{
        font-size: 0.7rem;
        margin-top: -40px;
        border: 2px solid white;
        padding: 8px 15px;
        margin-bottom: 1em;
    }

    .squiggly-bottom-right-image{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 615px;
        height: 246px;
        display: block;
    }

}

@media screen and (min-width: 760px) and (max-width: 990px) {
    .question-main-container > h1{
        font-size: 2.344vw;
    }

    .questions-about > h2{
        font-size: 1.953vw;
    }

    .questions-about > p{
        font-size: 1.563vw;
    }
}