@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.home {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  margin: 0;
  background: rgba(249, 249, 250, 1);
  overflow: hidden;
}

.landing-details {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  font-size: 2.5vw;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  margin-top: 40px;
  margin-bottom: 20px;
  /* background-color: red; */
}

.landing-summary {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.landing-summary > p {
  font-family: "Inter", sans-serif;
  font-size: 1.042vw;
  font-weight: 600;
  line-height: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.landing-summary > p > img {
  margin-right: 4px;
}

.homepage-vid-container {
  display: flex;
  width: auto;
  position: relative;
  justify-content: center;
  /* background-color: red; */
}

.landing-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 99vw;
  height: 36.5rem;
}

.homepage-overview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  height: auto;
}

.delivery-avatar {
  width: 38rem;
  height: 38rem;
  /* margin-top: 5rem; */
}

.delivery-avatar1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 28rem;
  height: 28rem;
  object-fit: contain;
}

.homepage-video-parent {
  position: relative;
  padding: 10px;
  width: 65rem;
  height: 70vh;
  margin-left: -10rem;
  margin-bottom: 0;
  border-radius: 10px;
  overflow: hidden;
  /* background-color: red;  */
}
.homepage-video {
  position: relative;
  width: 100%;
  height: 60vh;
  border-radius: 10px;
  overflow: hidden; /* Ensure content within the container doesn't overflow. Without this property the border radius wont take effect */
  margin: 0;
}
.video-player-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-right: 8%;
}

.video-buttons {
  height: 40px;
  width: 40px;
}

.home-full-buttons{
  border: none;
  background-color: transparent;
  font-size: 40px;
  cursor: pointer;
  font-weight: 700;
}

.handovericon {
  width: 100%;
  height: 100%;
}

.playicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  height: 5rem;
}

.downloadapp-home-v {
  width: 23.3rem;
  height: 23.3rem;
}

.downloadapp-home-v1 {
  width: 100%;
  height: 100%;
}

.downloadapp-home-v2 {
  width: 12.5rem;
  height: 4.9rem;
  position: fixed;
  top: 85%;
  right: 5%;
  animation: increaseSize 0.7s infinite alternate;
  z-index: 9999;
  cursor: pointer;
}


@keyframes increaseSize {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

.downloadapp-home-v3 {
  display: none;
}

.landing-guide {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90vw;
  margin-right: auto;
  margin-left: auto;
}

.hiw {
  font-family: "Inter";
  font-size: 1.875vw;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #101828;
  padding-bottom: 20px;
}

.landing-steps1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 30%;
}

.landing-steps1 > h2 {
  color: rgb(31, 179, 31);
  margin: 1;
  font-size: 1.506vw;
}

.landing-steps1 > h3 {
  font-family: "Inter";
  font-size: 1.094vw;
  font-weight: 700;
  text-align: center;
  line-height: 1px;
  margin: 0;
}

.landing-steps1 > p {
  font-family: "Inter";
  font-size: 1.098vw;
  font-weight: 500;
  text-align: center;
}


.landing-steps-image {
  width: 3.688vw;
  height: 3.688vw;
  background: rgba(249, 249, 250, 1);
  box-shadow: 0px 4px 16.3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  padding: 6px;
}

.landings-testimonial {
  font-weight: 400;
  font-weight: 400;
  font-family: "Mochiy Pop One", sans-serif;
  font-size: 1.979vw;
  text-align: center;
  letter-spacing: 2px;
}

.landings-testimonial > span {
  color: rgb(31, 179, 31);
}

.homepage-delivery-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: auto;
}

.homepage-delivery-row {
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
}

.homepage-delivery-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -70px;
  margin-left: 2.3rem;
}

.homepage-delivery-title > h2 {
  font-size: 37px;
  font-weight: 600;
  font-family: "Courier New", Courier, monospace;
  margin-bottom: -12px;
  letter-spacing: 2px;
}

.homepage-delivery-title > p {
  font-size: 12px;
}

.homepage-delivery-icon {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  margin-top: 21px;
}

.fauci-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.motion-container-text {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  padding: 20px;
  padding-left: 5%;
}

.motion-text-img {
  margin-bottom: 20px;
}

.motion-text-img > img{
  width: 11.979vw;
  height: 3.177vw;
}

.motion-text-ctn{
  margin-left: 9%;
}

.motion-text-ctn > h1 {
  font-family: "Mochiy Pop One", sans-serif;
  font-size: 1.667vw;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
}

.motion-text-ctn > p {
  font-family: "Mochiy Pop One", sans-serif;
  font-size: 1.667vw;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
}

.homepage-delivery-picture {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-summary-pic {
  width: 29px;
  height: 29px;
}



.modal {
  position: fixed;
  bottom: 5%;
  right: 2%;
  width: auto;
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
}

.modal-content {
  padding: 10px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .modal{
    right: 8%;
  }

  .homepage-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }

  .landing-summary {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0;
  }

  .landing-summary > p {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  .delivery-boy {
    display: none;
  }

  .landing-top {
    display: flex;
    flex-direction: column;
  }

  .landing-details {
    font-size: 1.7rem;
    font-weight: 700;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .landing-top {
    display: flex;
    flex-direction: column;
  }

  .hiw {
    font-size: 1.5rem;
  }

  .landing-guide {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    margin: 0;
  }

  .landing-steps1 {
    margin-bottom: 1rem;
    width: 90%;
  }


  .landing-steps-image {
    width: 3rem;
    height: 3rem;
  }

  .landing-steps1 > h2 {
    font-size: 1rem;
  }

  .landing-steps1 > h3 {
    font-size: 1rem;
    width: 100%;
  }

  .landing-steps1 > p {
    font-size: 1rem;
    width: 80vw;
  }
  .landings-testimonial {
    margin-top: -25px;
    font-family: "Mochiy Pop One", sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
  }

  .homepage-delivery-container {
    margin: 0;
    padding: 0;
  }

  .homepage-delivery-row {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 85vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }




  .homepage-delivery-icon1 {
    display: block;
    width: 36px;
    height: 36px;
    /* margin-top: 7px; */
  }


  .homepage-vid-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    position: relative;
  }

  .landing-overlay {
    width: 100%;
    height: 10rem;
    margin: 0;
  }

  .landing-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 99vw;
    height: 10.5rem;
  }

  .delivery-avatar {
    width: 16.7rem;
    height: 16.7rem;
    margin-bottom: -6vh;
    position: relative;
  }

  .delivery-avatar1 {
    width: 100%;
    height: 96%;
  }

  .homepage-overview-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .homepage-video-parent {
    position: relative;
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .homepage-video {
    width: 98%;
    /* width: 17rem; */
    height: 10rem;
    position: relative;
    margin: 0;
    border-radius: 20px;
  }

  .video-player-buttons {
    margin-top: -1px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .video-buttons {
    width: 1.5rem;
    height: 1.5rem;
  }

  .handovericon {
    width: 100%;
    height: 100%;
  }

  .playicon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 2rem;
  }

  .downloadapp-home-v {
    width: 12rem;
    height: 12rem;
    margin-top: -9vh;
  }

  .downloadapp-home-v1 {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .downloadapp-home-v2 {
    display: none;
    width: 4.15rem;
    height: 3.85rem;
  }

  .downloadapp-home-v3 {
    display: block;
    width: 4.15rem;
    height: 3.85rem;
    position: fixed;
    bottom: 5%;
    right: 5%;
    animation: increaseSize 0.7s infinite alternate;
    z-index: 9999;
    cursor: pointer;
    margin: 0;
  }

  @keyframes increaseSize {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }

  .fauci-content{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 40vh;
  }

  .motion-container-text{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .motion-text-img > img{
    width: 139px;
    height: 40px;
  }

  .motion-text-ctn{
    margin-left: -1%;
  }

  .motion-text-ctn > h1{
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    text-align: left; 
    margin-bottom: 1rem;
  }

  .motion-text-ctn > p{
    font-family: "Inter";
    font-size: 1.2rem;
    width: 60%;
    font-weight: 400;
    line-height: 22px;
    text-align: left;  
    margin-bottom: 1rem;  
  }

  .homepage-delivery-picture{
    width: 100%;
    position: absolute;
    top: 33%;
    left: 20%;
  }

  .homepage-delivery-picture > img{
    width: 180px;
    height: 172px;
  }

  .modal{
    position: fixed;
    left: 5%;
    top: 5%;
    width: 90vw;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .homepage-overview-container {
    display: flex;
    flex-direction: row;
    margin-top: -5rem;
  }

  .landing-summary{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  .landing-summary > p{
    font-size: 1rem;
  }

  .landing-summary-pic{
    width: 15px;
    height: 15px;
  }

  .homepage-video-parent {
    margin-top: 10rem;
    width: 70%;
    height: 70%;
  }

  .homepage-video {
    width: 100%;
    height: 20vh;
  }

  .landing-details {
    display: flex;
    flex-direction: row;
  }

  .downloadapp-home-v {
    margin-top: 5rem;
  }

  .landing-guide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;
  }

  .landing-steps-image {
    width: 40px;
    height: 40px;
  }

  .landing-steps1 > p {
    font-size: 1rem;
    width: 100%;
    margin: 1rem 1rem;
  }

  .landing-steps2 > p {
    font-size: 1rem;
    width: 100%;
    margin: 1rem 1rem;
  }

  .landing-steps3 > p {
    font-size: 1rem;
    width: 100%;
    margin: 1rem 1rem;
  }

  .downloadapp-home-v2 {
    display: block;
    width: 20%;
  }

  .downloadapp-home-v3 {
    display: none;
  }

  .motion-text-img > img{
    width: 60%;
    height: auto;
  } 

  .motion-text-ctn{
    width: 100%;
  }

  .motion-text-ctn > h1{
    font-size: 1.5rem;
  }

  .motion-text-ctn > p{
    font-size: 1.3rem;
  }

  .homepage-delivery-picture{
    top: 0;
    margin: 0;
  }

  .homepage-delivery-picture > img{
    width: 70%;
    height: 300px;
  }

  
}

