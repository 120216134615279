@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.download-app-main{
    background-color: rgba(217, 217, 217, 1);
    height: fit-content;
    margin-top: 90px;
    padding-bottom: 40px;
}


.download-app-container{
    width: 94vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: auto;
    margin-left: auto;
}

.download-segment{
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
}

.download-title{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.download-title-icon {
    width: 3.385vw;
    height: 3.385vw;
}

.download-title > h2 {
    font-family: "Mochiy Pop One";
    font-size: 2.385vw;
    font-weight: 400;
    text-align: left;
    margin-left: 20px;
    margin-top: 1px;

}

.download-segment > h3 {
    font-family: 'Inter';
    font-size: 1.354vw;
    font-weight: 500;
    text-align: left;
    margin-left: 13%;
    margin-top: -10px;
    color: #222222;
}

.download-button{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;
    cursor: pointer;
    gap: 30px;
}

.download-button > button{
    background: none;
    border: none;
    border-radius: 6px;
    margin-left: 20px;
}

.grouphone-segment {
    display: flex;
    justify-content: space-between;
    margin-top: -30px;
    width: 31rem;
    height: 36rem;
    position: relative;
}

.grouphone1{
    width: 16rem;
    height: 33rem;
    display: flex;
    margin: 0 auto;
}

.phonebody1{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.grouphone2{
    width: 12rem;
    height: 27rem;
    position: absolute;
    bottom: 0;
    left: 0;
}

.phonebody2{
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.grouphone3{
    width: 10.5rem;
    height: 23rem;
    position: absolute;
    bottom: 0;
    right: 0;
}

.phonebody3{
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.download-btn-icon{
    height: 3.125vw;
    object-fit: contain;
    cursor: pointer;
    transition: ease 0.3s;
    animation: increaseSize 0.7s infinite alternate;
}

.download-btn-icon:hover{
    transform: scale(1.2);
}

@keyframes increaseSize {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }
  

.grouphoneportrait{
    display: none;
}

@media screen and (min-width: 350px) and (max-width: 780px) {
    .download-app-main{
        margin: 0;
    }
    
    .download-app-container{
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .download-title-icon{
        display: none;
    }

    .download-title > h2 {
        font-family: "Mochiy Pop One";
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }

    .download-segment > h3 {
        font-family: 'Inter';
        font-size: 19px;
        font-weight: 500;
        text-align: center;
        margin-left: 25px;
        margin-top: -10px;
    }

    .download-button{
        display: flex;
        flex-direction: row;
        margin-right: auto;
        margin-left: auto;
        margin-top: 10px;
        margin-bottom: 60px;
    }

    .download-btn-icon{
        width: 127px;
        height: 40px;
    }

    .grouphone-segment {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: -30px;
        width: 20rem;
        height: 22rem;
        margin: 0 auto;
        position: relative;
    }
    
    .grouphone1{
        width: 9rem;
        height: 20rem;
        display: flex;
        margin: 0 auto;
    }
    
    .phonebody1{
        width: 100%;
        height: 100%;
    }
    .grouphone2{
        width: 8rem;
        height: 16rem;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    
    .phonebody2{
        width: 100%;
        height: 100%;
    }
    
    .grouphone3{
        width: 6.7rem;
        height: 14rem;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    
    .phonebody3{
        width: 100%;
        height: 100%;
    }
    

    .groupphone-container{
        display: none;
    }

    .grouphoneportrait{
        display: block;
        width: 21rem;
    }

    

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .download-title-icon{
        width: 5.273vw;
        height: 5.273vw;
    }

    .download-title > h2{
        font-size: 3.25vw;
    }

    .download-segment > h3{
        font-size: 2.539vw;
    }

    .grouphone-segment{
        width: 48.438vw;
    }

    .grouphone1{
        width: 26.074vw;
    }

    .grouphone2{
        width: 18.75vw;
        height: 42.188vw;
    }
    .grouphone3{
        width: 16.406vw;
        height: 35.938vw;
    }

    .grouphone-segment{
        margin: 0 auto;
    }

    .download-button{
        gap: 5px;
        margin: 4% 0;
        width: 60%;
    }

    .download-button > button{
        margin: 0;
    }

    .download-btn-icon{
        width: 25.4vw;
        height: 5vw;
    }



}