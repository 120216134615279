@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.navbar {
    background: rgba(249, 249, 250, 1);
    padding: 10px 0;
    z-index: 1;
    overflow: hidden;
}
  
.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 30px;
    padding: 0 20px;
}
.nav-bar-title{
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-title-icon{
    width: 1.823vw;
    height: 1.823vw;
    margin-right: 3%;
}

.nav-title-img{
    width: 7.813vw;
    height: 3.125vw;
}

.pickload-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pickload-container > img {
    margin-left: 10px;
}

.pickload-container > button {
    background-color: transparent;
    border-radius: none;
    border: none;
     
}

.hamburger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}
  
.hamburger .bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px 0;
}
  

.topbar-about{
    width: 50vw;
    margin-right: -30px;
}


.nav-menu {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
  
.nav-item {
    margin-right: 20px;
}

.nav-link{
    color: black;
    text-decoration: none;
    padding: 5px 10px;
    font-size: 1.25vw;
    font-weight: bold;
    font-family: "inter", sans-serif;
    width: fit-content;
}
   

.nav-link > button {
    color: black;
    text-decoration: none;
    padding: 0.677vw 1.354vw;
    font-size: 1.25vw;
    font-weight: 600;
    background-color: #F8DE0F;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);  
    cursor: pointer;   
    margin-right: 1rem;
}


  
@media (max-width: 768px) {
    .navbar{
        margin: 0;
    }

    .nav-title-icon{
        width: 26px;
        height: 26px;
    }

    .nav-title-img{
        width: 83px;
        height: 32px;
    }

    .pickload-container > button {
        display: flex;
        flex-direction: row;
    }

    .hamburger-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

    }

    .hamburger-btn {
        position: absolute;
        top: 2%;
        right: 5%;

    }

    .hamburger {
      display: block;
    }
    
    .nav-menu {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 45px;
      left: 0;
      width: 100%;
      background: rgba(249, 249, 250, 1);
      z-index: 1;
    }
  
    .nav-menu.active {
      display: flex;
    }
  
    .nav-item {
      margin: 0;
      padding: 10px;
      text-align: center;
    }

    .nav-link{
        font-size: 1rem;
    }

    .nav-link > button {
        color: black;
        text-decoration: none;
        padding: 10px 16px;
        font-size: 1rem;
        font-weight: 600;
        background-color: #F8DE0F;
        border: none;
        border-radius: 4px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }
    
}


@media screen and (min-width: 760px) and (max-width: 920px) {
    .pickload-container > button {
        display: flex;
        flex-direction: row;
    }

    .topbar-about{
        width: 60vw;
    }

    .nav-item{
        margin: 0;
        padding: 10px;
        text-align: center;
    }

    
}

