@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");


.login-main{
    overflow-x: hidden;
    overflow-y: hidden;
}

.login-main-ctn{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
}

.login-ctn{
    width: 50%;
}

.login-img{
    width: 50%;
}

.login-img > img{
    object-fit: cover;
    overflow-y: hidden;
}

.login-props{
    width: 50%;
    margin: 2rem auto;
}

.login-text{
    text-align: center;
}

.login-text > h1 {
    font-family: "Mochiy Pop One";
    font-size: 1.719vw;
    font-weight: 400;
    text-align: center;
    color: rgba(0, 0, 0, 1);    
}

.login-text > p {
    font-family: "Inter";
    font-size: 1.042vw;
    font-weight: 400;
    text-align: center;
    color: rgba(138, 146, 166, 1);    
}

.login-text > h2 {
    font-family: "Inter";
    font-size: 1.042vw;
    font-weight: 400;
    text-align: center;
    color: rgba(138, 146, 166, 1);    
}

.login-text > h2 > a {
    color: rgba(31, 170, 8, 1);
    text-decoration: none;
}

.form-group{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 1rem 0;
}

.form-group > label{
    font-family: "Inter";
    font-size: 0.938vw;
    font-weight: 400;
    text-align: left;
    color: rgba(138, 146, 166, 1);    
}

.form-group > input{
    font-size: 0.938vw;
    padding: 0.417vw 0.417vw;
    color: rgba(138, 146, 166, 1);
    border:  1px solid rgba(138, 146, 166, 1);
    border-radius: 2px;
    margin: 6px 0;
}

.login-btn{
    font-family: "Inter";
    font-size: 0.938vw;
    font-weight: 700;
    text-align: center;
    padding: 0.538vw 5.25vw;
    color: rgba(255, 255, 255, 1);    
    border: none;
    margin-bottom: 10%;
    background-color: rgba(31, 170, 8, 1);
}

.spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.pickload-sign-icon{
    width: 1.823vw;
    height: 1.823vw;
    margin-right: 10px;
}

.pickload-title-icon-ctn{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.login-footer{
    display: none;
}

@media (max-width: 768px) {
    .login-main{
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    .login-main-ctn{
        display: flex;
        flex-direction: column;
    }

    .login-ctn{
        width: 100%;
    }

    .login-ctn > img{
        width: 25%;
        height: 35%;
    }

    .login-props{
        width: 80%;
    }

    .pickload-title-icon-ctn{
        display: none;
    }

    .pickload-sign-icon{
        width: 30px;
        height: auto;
    }

    .pickload-title-icon{
        width: 8rem;
    }

    .login-text{
        margin-top: -15%;
    }

    .login-text > h1{
        font-size: 1.5rem;
    }

    .login-text > p {
        font-size: 1.1rem;
    }

    .form-group{
        width: 100%;
    }

    .form-group > label{
        font-size: 15px;
    }

    .form-group > input{
        font-size: 14px;
        padding: 10px 15px;
    }

    .login-btn{
        font-size: 14px;
        padding: 0.5rem 1.9rem;
        border-radius: 3px;
    }

    .login-img {
        width: 100%;
        display: none;
    }

    .login-footer{
        display: block;
        margin-top: -100%;
    }


}