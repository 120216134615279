/* OtpPage.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}

.otp-container {
    max-width: 400px;
    margin: 50px auto;
    text-align: center;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.otp-container > h1 {
    margin-bottom: 10px;
    font-size: 24px;
    color: #333;
}

.otp-container > p {
    color: #666;
    font-size: 14px;
}

.otp-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}

.otp-box {
    width: 40px;
    height: 50px;
    text-align: center;
    font-size: 18px;
    border: 2px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

.otp-box:focus {
    border-color: rgba(31, 170, 8, 1);
    box-shadow: 0px 0px 4px rgba(31, 170, 8, 1);
}

.submit-btn {
    background-color: rgba(31, 170, 8, 1);
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: rgb(7, 75, 15);
}

.submit-btn:focus {
    outline: 2px solid rgb(7, 75, 15);
    outline-offset: 2px;
}

.resend {
    margin-top: 15px;
    font-size: 14px;
    color: rgba(31, 170, 8, 1);
    font-weight: bold;
    text-decoration: none;
}

.resend:hover,
.resend > a:hover {
    text-decoration: underline;
    cursor: pointer;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* input[type="number"] {
    -moz-appearance: textfield;
} */


@media screen and (max-width: 768px) {
    .otp-container{
        width: 80%;
    }
}