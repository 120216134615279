@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mochiy+Pop+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");


.chat-page1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
}

.chat-section1 {
  padding: 20px;
  background-color: #e0e5eb;
  position: relative;
  width: 100%;
  height: 75vh;
  margin: 0;
}

.chat-title1 {
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: 700;
  background: rgba(249, 249, 250, 1);
  margin-top: -1.5%;
  text-align: center;
  padding: 1rem 0;
  position: absolute;
  width: 100%;
  margin-bottom: 2rem;
}


.message-bubble {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  margin-bottom: 10px;
}

.message-time {
  font-size: 8px;
  display: flex;
  flex-direction: column;
  margin-top: -8px;
  margin-left: 15px;
}

.sent {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  font-family: "Tahoma";
  margin-right: 1.6rem;
}

.message-bubble h2 {
  color: black;
  font-weight: 400;
}

.messenger {
  background-color: #ffffff;
  border-radius: 20px 20px 0 20px;
  padding: 16px;
  max-width: 90%;
  width: fit-content;
  color: black;
  margin: 5px 1px;
  font-size: 13px;
  font-weight: 500;
  font-family: "Tahoma";
}

.chat-profile-pic {
  width: 17px;
  height: 17px;
}

.chat-profile-pic-container {
  border-radius: 50%;
  background-color: white;
  width: 1.7rem;
  height: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.received {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-left: 10px;
}
/* 
.received .message-bubble {
  background-color: #fff;
  border-radius: 0px 30px 30px 30px;
  padding: 10px;
  max-width: 70%;
} */

.received .message-bubble p {
  background-color: #ffffff;
  color: black;
  border-radius: 0px 20px 20px 20px;
  padding: 15px;
  max-width: 70%;
  font-size: 0.8rem;
  font-family: "Tahoma";
  font-weight: 500;
}
.form-message {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #eef2f5;
  padding: 10px 0;
  margin: 0;
}
.form-message > textarea {
  flex: 1;
  padding: 10px;
  border: none;
  resize: none;
  background-color: transparent;
  border-top-style: none;
  border-right-style: solid;
  border-bottom-style: none;
  border-left-style: none;
  font-size: 0.9rem;
  outline: none;
  margin: 0 2% 0 5%;
  height: 5%;
}

.messages-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  scroll-behavior: smooth;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 50vh;
}

.messages-wrapper::-webkit-scrollbar {
  display: none;
}

.message > button[type="submit"] {
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.send-message {
  width: 45px;
} 

.attachment {
  margin-right: 5px;
}

.send-message img {
  width: 100%;
  cursor: pointer;
}

.chat-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 5rem;
  /* background-color: red; */
}

.loader-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30vh;
}


.conversation-date {
  font-family: "Montserrat";
  font-size: 0.5rem;
  font-weight: 700;
  letter-spacing: -0.06em;
  text-align: center;
  background-color: white;
  padding: 8px 12px;
  border-radius: 11px;
  opacity: 0px;
  display: flex;
  color: #606060;
  height: fit-content;
}

.conversation-design {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  position: relative;
  margin: 1rem auto;
}

.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
}

.loading-spinner {
  border: 4px solid #f3f3f3; /* Light grey border */
  border-top: 4px solid green; /* Blue border for animation */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 350px) and (max-width: 780px) {
  
  .chat-page{
    width: 100%;
    height: auto;
    overflow: hidden;
    height: 100%;
  }

  .chat-section {
    background-color: #e0e5eb;
    position: relative;
    width: 100%; 
    height: auto;
    height: 100%;
  }

  .messages-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 8px;
    height: auto;
    overflow-x: scroll;
  } 

 

  .chat-title1 {
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 700;
    line-height: 31.49px;
    letter-spacing: -0.06em;
    text-align: center;
    padding: 10px 16px;
    width: 84%;
    position: absolute;
    margin-top: -5.5%;
  }

  .conversation-design {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .conversation-date {
    font-family: "Montserrat";
    font-size: 0.5rem;
    font-weight: 700;
    letter-spacing: -0.06em;
    text-align: center;
    color: black;
    padding: 0;
    height: fit-content;
    padding: 4px 8px;
    margin-top: 1.5rem;
  }

  .message-time {
    font-family: "Inter";
    font-size: 0.5rem;
    font-weight: 400;
  }

  .chat-profile-pic {
    width: 1rem;
    height: 1rem;
  }

  .chat-profile-pic-container {
    border-radius: 50%;
    background-color: white;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -0.4rem;
  }

  .received .message-bubble p {
    background-color: #ffffff;
    color: black;
    border-radius: 0px 30px 30px 30px;
    padding: 15px 20px;
    max-width: 70%;
    font-size: 0.7rem;
  }

  .messenger {
    background-color: #ffffff;
    border-radius: 30px 30px 0 30px;
    padding: 20px;
    max-width: 95%;
    color: black;
    margin: 5px 0;
    font-size: 0.7rem;
  }

  .form-message {
    display: flex;
    margin-top: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #eef2f5;
    padding: 5px 0;
    outline: none;
  }

  .form-message textarea {
    padding: 10px 10px 10px 15px;
    border: none;
    margin-right: 10px;
    resize: none;
    background-color: transparent;
    border-top-style: none;
    border-right-style: solid;
    border-bottom-style: none;
    border-left-style: none;
    font-size: 0.7rem;
    outline: none;
    width: 100%;
  }
  
  .chat-icons {
    display: flex;
    align-items: center;
    width: auto;
    margin-right: 1.5rem;
  }

  .send-message-icon{
    height: auto;
  }

  .upload-picture > button {
    width: 1rem;
    height: 1rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

 

  .send-message {
    width: 2.6rem;
    height: auto;
  }
}

@media screen and (min-width: 781px) and (max-width: 1110px) {

  .chat-page{
    width: 45vw;
    height: 50vh;
  }
  
  .chat-section {
    width: 44vw;
  }

  .messages-wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 104%;
  }

  .chat-title {
    font-family: "Montserrat";
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 31.49px;
    letter-spacing: -0.06em;
    text-align: center;
    padding: 8px;
    width: 46vw;
    /* margin-left: -6px; */
  }

  .conversation-design {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .conversation-date {
    font-family: "Montserrat";
    font-size: 0.5rem;
    font-weight: 700;
    letter-spacing: -0.06em;
    text-align: center;
    color: black;
    padding: 0;
    height: fit-content;
    padding: 4px 8px;
  }

  .message-time {
    font-family: "Inter";
    font-size: 0.7rem;
    font-weight: 400;
  }

  .chat-profile-pic {
    width: 1.6rem;
    height: 1.6rem;
  }

  .chat-profile-pic-container {
    border-radius: 50%;
    background-color: white;
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -0.6rem;
  }

  .received .message-bubble p {
    background-color: #ffffff;
    color: black;
    border-radius: 0px 20px 20px 20px;
    padding: 15px;
    max-width: 70%;
    font-size: 0.8rem;
  }

  .messenger {
    background-color: #ffffff;
    border-radius: 20px 20px 0 20px;
    padding: 15px;
    max-width: 90%;
    color: black;
    margin: 3px 1px;
    font-size: 0.8rem;
  }

   .form-message textarea {
    flex: 1;
    padding: 10px;
    border: none;
    margin-right: 10px;
    resize: none;
    background-color: transparent;
    border-top-style: none;
    border-right-style: solid;
    border-bottom-style: none;
    border-left-style: none;
    font-size: 0.7rem;
    /* padding-left: 40px; */
    outline: none;
  }
  .chat-icons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90px;
    margin-right: 0.5rem;
  }

  .upload-picture > button {
    width: 2.6rem;
    height: 1.7rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .upload-image {
    width: 100%;
    height: 100%;
  }

  .send-message {
    width: 2.6rem;
    height: auto;
  }
}
